<template>
  <v-row v-if="product" class="product-detail">
    <v-col cols="12" sm="6" class="py-0 py-sm-3">
      <v-card flat v-if="selectedImage" class="product-images-card">
        <ProductImages
          :images="product.media"
          :name="product.name"
          :key="product.productId"
        />
        <div class="product-classes">
          <ProductAcqLimit product="product" />
          <ProductClass
            :pClass="pClass"
            v-for="pClass in productClasses"
            :key="pClass.productClassid"
          />
        </div>
      </v-card>
    </v-col>

    <v-col
      cols="12"
      sm="6"
      class="py-0 py-sm-3 default--text"
      style="position:relative"
    >
      <h1 class="mt-6">
        {{ product.name }}
      </h1>

      <h2>
        {{ product.shortDescr }}
      </h2>
      <span
        class="text-caption"
        v-if="
          product.productInfos.TIPOLOGIA == 'Pezzo' &&
            product.productInfos.PACKAGE_DESCR
        "
      >
        {{ product.productInfos.PACKAGE_DESCR }}
      </span>

      <v-row no-gutters class="text-body-1">
        <span class="font-weight-bold">
          {{ product.description }}
        </span>
        <span
          class="ml-2"
          v-if="
            product.productInfos &&
              product.productInfos.TIPOLOGIA == 'Pezzo' &&
              product.priceUmDisplay
          "
        >
          al {{ product.weightUnitDisplay }}
          {{ $n(product.priceUmDisplay, "currency") }}
        </span>
      </v-row>
      <div class="mt-4">COD. {{ product.codInt }}</div>
      <v-row
        no-gutters
        class="mb-4 mb-sm-0 mb-md-3 mt-3"
        justify="center"
        justify-sm="start"
      >
        <ProductClass
          :pClass="pClass"
          :width="34"
          :height="34"
          v-for="pClass in productClasses"
          :key="pClass.productClassId"
        />
      </v-row>

      <!-- <div class="promo-wrapper"> -->
      <!-- <ProductClass
          :pClass="pClass"
          v-for="pClass in promoProductClasses"
          :key="pClass.productClassid"
        /> -->
      <!-- <product-gift-certificate
          v-for="userGiftCertificate in product.userGiftCertificates"
          :key="userGiftCertificate.giftCertificate.giftCertificateId"
          :giftCertificate="userGiftCertificate.giftCertificate"
          @add="giftAdded"
        /> -->
      <!-- <ProductAcqLimit :product="product" class="mr-1" /> -->
      <!-- </div> -->
      <!-- <span
        v-if="product.warehousePromo && product.warehousePromo.view.bubble"
        class="promo-badge"
        v-html="product.warehousePromo.view.bubble"
      >
      </span> -->
      <span
        class="option d-flex align-center justify-space-between line-height-1 my-2"
        style="white-space: initial;text-align: left;"
        v-for="(value, label) in selectedOptions"
        :key="label"
      >
        <div>
          <i>
            Preferenza: <b> {{ value }}</b></i
          >
        </div>
      </span>

      <div
        v-if="product.available > 0"
        :style="{ 'min-height': product.warehousePromo ? '70px' : '' }"
      >
        <ProductPrice :product="product"></ProductPrice>

        <ProductQty
          class="mt-3"
          :selectedOptions="selectedOptions"
          :product="product"
          :showAddToCartLabel="true"
          :isProductDetail="true"
          :inCart="inCart"
        />
      </div>
      <div
        v-else-if="product.available == 0"
        class="actions justify-center text-center text-caption error--text font-weight-bold"
      >
        {{ $t("product.notAvailable") }}
      </div>

      <div class="d-flex flex-column mt-4">
        <div class="d-flex align-center" @click="addToFavorites">
          <v-btn icon large>
            <v-icon v-if="highlight" color="primary">$heartfull</v-icon>
            <v-icon v-else>$heart</v-icon>
          </v-btn>

          <span class="text-body-2">{{ $t("product.addToList") }}</span>
        </div>
        <div class="caption" v-if="product.media">
          <span class="point"></span> L'eventuale data di scadenza presente
          nella foto non è riferita al prodotto in vendita.<br />
          I produttori possono modificare la composizione e l'origine dei loro
          prodotti. Pertanto, la confezione del prodotto può contenere
          informazioni diverse rispetto a quelle mostrate sul sito.<br />
          Si prega di leggere sempre l'etichetta, gli avvertimenti e le
          istruzioni fornite sul prodotto prima di utilizzarlo o consumarlo.
        </div>
      </div>
    </v-col>
  </v-row>
</template>
<style lang="scss">
.product-detail {
  .product-images-card {
    position: relative;
    .product-classes {
      position: absolute;
      top: 6px;
      right: 5px;
    }
  }
  span.point::before {
    content: " \25CF";
    font-size: 20px;
    line-height: 12px;
    color: #ed7c20;
  }
  h1 {
    font-size: 32px;
    line-height: 33px;
  }
  h2 {
    font-size: 23px;
    font-weight: 600;
  }
  .product-price {
    margin-bottom: 20px !important;
    height: initial;
    .card {
      right: -40px;
      left: initial;
    }
    .price-block {
      display: flex;
      align-items: center;
    }
  }
  .product-price.product_promo {
    position: relative;
    margin-bottom: 8px;
    & > .card {
      bottom: 44px;
    }
    .bubble {
      top: 0;
      bottom: 0;
      left: 190px;
    }
  }
  .vendorIcon {
    position: absolute;
    right: 6px;
    top: -18px;
    z-index: 6;
  }

  .price-um {
    font-size: 12px !important;
    margin-top: 5px !important;
  }
  .add-to-list-text.text-body-2 {
    font-size: 16px !important;
  }
}
.is-cordova {
  .product-detail {
    padding-top: 18px !important;
    padding-bottom: 18px !important;
  }
}
</style>
<script>
import ProductPrice from "@/components/product/ProductPrice.vue";
// import ProductPromo from "@/components/product/ProductPromo.vue";
import ProductClass from "@/components/product/ProductClass.vue";
import ProductImages from "@/components/product/ProductImages.vue";
import ProductQty from "./ProductQty.vue";
// import ProductRating from "@/components/product/ProductRating.vue";
// import ProductCustomerVotes from "@/components/product/ProductCustomerVotes.vue";

import productMixin from "~/mixins/product";
import productVariations from "@/mixins/productVariations";
import { mapState, mapGetters } from "vuex";

import cloneDeep from "lodash/cloneDeep";
import ProductAcqLimit from "./ProductAcqLimit.vue";

export default {
  name: "ProductDetail",
  props: ["selectedImage", "product"],
  components: {
    ProductPrice,
    ProductAcqLimit,
    // ProductPromo,
    ProductClass,
    ProductImages,
    // ProductGiftCertificate,
    ProductQty
    // ProductRating
    // ProductCustomerVotes
  },
  mixins: [productMixin, productVariations],
  data() {
    return {
      imageDetailDialog: false,
      selectedOptions: {},
      selectOptionsArray: []
    };
  },
  computed: {
    ...mapGetters({
      getItem: "cart/getItem"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    item() {
      return null;
    },
    inCart() {
      let item = this.getItem({
        product: this.product,
        ...this.selectedOptions
      });
      return item != null;
    }
  },
  methods: {
    goToProductCustomerVote() {
      // this.$vuetify.goTo(this.$refs.disclaimerform);
      this.$emit("goToCustomerVote", true);
    },
    selectImage(media) {
      this.$emit("selectImage", media);
    },
    openImageDialog() {
      this.imageDetailDialog = true;
    },
    setOptionsArray() {
      if (this.product.selectOptions && this.product.selectOptions.length) {
        this.selectOptionsArray = cloneDeep(this.product.selectOptions);
      } else {
        this.selectOptionsArray = [];
      }
    },
    handleSelectedVariation(id) {
      let p = this.product.variations.find(product => product.productId === id);
      this.$emit("chageProduct", p);
    }
  },
  created() {
    this.setOptionsArray();
  },
  watch: {
    product(val, oldVal) {
      if (val.productId !== oldVal.productId) {
        this.setOptionsArray();
      }
    }
  }
};
</script>
